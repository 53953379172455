import React from 'react'
import './Testimonials.css';
import '../../App.css';
import {testimonialsData} from "../../data/testimonialsData";
import leftArrows from '../../assets/leftArrow.png';
import RightArrows from '../../assets/rightArrow.png';
import { useState } from 'react';
const Testimanials = () => {

    const [selected, setSelected] = useState(0);
    const tlength = testimonialsData.length; 


  return (
    <div className='Testimonials'>
        <div className='Left-t'>
            <span>Testimanials</span>
            <span className='stroke-text'>What They</span>
            <span>say About Us</span>
            <span>
                {testimonialsData[selected].review}
            </span>
            <span>
                <span style={{color: 'var(--orange)'}}>
                    {testimonialsData[selected].name}
                </span>{" "}
                - {testimonialsData[selected].status}
            </span>

        </div>
        <div className='Right-t'>
            <div className='outer-box'></div>
            <div></div>
            <img src={testimonialsData[selected].image} alt=' '/>
            <div className='arrows'>
                <img 
                    onClick={()=>{
                        selected===0? setSelected(tlength-1)
                        : setSelected((prev)=>prev-1);
                    }     
                    }
                
                src={leftArrows} alt=' '/>
                <img 
                    onClick={()=>{
                        selected===(tlength-1)?setSelected(0)
                        :setSelected((prev)=>prev+1);
                        }
                    }
                src={RightArrows} alt=' '/>
            </div>


        </div>
      
    </div>
  )
}

export default Testimanials;
