import React, { useRef } from 'react';
import './Join.css';
import emailjs from '@emailjs/browser';
import '../../App.css';
const Join = () => {
    const form = useRef()
    const sendEmail = (e) =>{
        e.preventDefault();

        emailjs.sendForm('service_qpufysz','template_ez8h13d',form.current,
        'KtpVzqPcvyUWS1IOp').then((result) =>{
            console.log(result.text);
        },(error)=>{
            console.log(error.text);   
        })
    }
  return (
    <div className='Join'>
        <div className='left-j'>
            <hr/>
            <div>
                <span className='stroke-text'>Ready to </span>
                <span>Level Up</span>
            </div>
            <div>
                <span>Your Body</span>
                <span className='stroke-text'>With Us?</span>
            </div>
        </div>
        <div className='right-j'>

            <form action='' className='email-container' onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder='Enter your email address'/>
                <button style={{width: '5.5rem'}} className='btn btn-join'>Join Now</button>
            </form>
        </div>


    </div>
  )
}

export default Join
